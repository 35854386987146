import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Card from "./Card";
import History from "../assets/images/author-history-1.jpg";
import { NavLink, Link, useLocation } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import axios from 'axios'
import { MdKeyboardArrowRight } from 'react-icons/md';
import config from "./config/config";
import Binance from '../assets/images/svg/binance.png'
import Ethereum from "../assets/images/ethuu.png";
import ImgAudVideo from "../separate/ImgAudVideo";
import { toast } from "react-toastify";
import { address_showing, isEmpty, getcurrency } from "../actions/common";
import { useNavigate, useParams } from "react-router-dom";
import useContractProviderHook from "../actions/contractProviderHook";
import { useSelector, useDispatch } from "react-redux";
import { Token_Info_Func } from "../actions/axioss/nft.axios";
import { BuyNow } from "./seperatemodals/BuyNow";
import { Lower } from "./seperatemodals/Lower";
import { Cancel } from "./seperatemodals/Cancel";
import { Bid } from "./seperatemodals/Bid";
import { Accept } from "./seperatemodals/Accept";
import { CancelBids } from "./seperatemodals/CancelBids";
import Button from "react-bootstrap/esm/Button";
import Countdown from "react-countdown";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import { LikeRef } from "./seperatemodals/LikeRef";
import { Share } from "./seperatemodals/Share";
import { Report } from './seperatemodals/Report';
import { findOwners } from "../actions/axioss/user.axios";
import author from "../assets/images/author-detail-3.png"
import author1 from "../assets/images/monkey1.png";
import { switchnetwork } from "../actions/common";
export default function Details() {
  const dispatch = useDispatch()
  const { state } = useLocation();
  const ContractCall = useContractProviderHook();
  const [TabName, SetTabName] = useState("All");
  const [Tokens, SetTokens] = useState({
    All: {
      loader: true,
      page: 1,
      list: [],
      owner: {},
      myBid: {},
      highbid: {},
      myowner: {},
    },
  });
  const [Tokens_Detail, SetTokens_Detail] = useState({});
 
  const [Explores, SetExplore] = useState([]);
  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);
  const [InfoDetail, SetInfoDetail] = useState({});
  console.log("InfoDetail",InfoDetail)
  var [seemore, setSeemore] = useState({});
  const { network, Contract, Owner, Id } = useParams()
  const { currency } = useSelector((state) => state.LoginReducer);
  const [Loader, setLoader] = useState(false);
  const [LoaderTab, setLoaderTab] = useState(false);
  const [OpenPopup, SetOpenPopup] = useState('')
  const [SendDet, SetSendDet] = useState({});
  const [BtnData, SetBtnData] = useState('start')
  const { accountAddress, web3 } = useSelector(state => state.LoginReducer.AccountDetails);
  var [moreprops, setMoreprops] = useState('');
  const [text, setText] = useState('')
  const push = useNavigate()
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
 
  const [likedisable, SetLikeDisable] = useState(true);
  const { Network } = useSelector(
    (state) => state.LoginReducer
  );
  
  const [btn , setbtn]= useState(false)


  useEffect(() => {

    getdata()
  }, [Network])


  const getdata = async () => {
    const data = await getcurrency(Network)
    dispatch(data)
  }

  useEffect(() => {
    setLoader(true);
    findOwner();
  }, [accountAddress, state, Contract, Owner, Id]);
  useEffect(() => {
    if (typeof Tokens[TabName] == 'undefined') {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    } else setLoaderTab(false)
  }, [TabName, Contract, Owner, Id, accountAddress])
  const findOwner = async () => {
    var Resp = await findOwners({
      NFTCreator: Owner,
      ContractAddress: Contract,
      NFTId: Id,
    });

    if (Resp.success === "success") {
      // console.log("come ah");
      Explore();
    } else if (state?.data && Resp.success === "error") {
      if (state?.data?.metadata?.animation_url) {
        var Response = await axios.get(state.data.metadata.animation_url)
        state.data.type = (Response.headers['content-type']).includes('image') ? "image" : (Response.headers['content-type']).includes('video') ? "video" : "audio"
      }
      SetInfoDetail(state.data);
      setLoader(false);
    } else {
      Explore();
    }
  };
  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (

        <>
   

          <div className="d-flex justify-content-center">
            <div className="equal_divs_time">
              <p className="dayy">{formatTime(days)} </p>
              <p className="dayss">Days </p>
            </div>
            <div className="equal_divs_time">
              <p className="dayy">:</p>
            </div>
            <div className=" equal_divs_time">
              <p className="dayy">{formatTime(hours)}</p>
              <p className="dayss">Hours </p>
            </div>
            <div className="equal_divs_time">
              <p className="dayy">:</p>
            </div>
            <div className=" equal_divs_time">
              <p className="dayy">{formatTime(minutes)}</p>
              <p className="dayss">Minutes</p>
            </div>
            <div className="equal_divs_time">
              <p className="dayy">:</p>
            </div>
            <div className=" equal_divs_time">
              <p className="dayy"> {formatTime(seconds)}</p>
              <p className="dayss">Seconds</p>
            </div>
          </div>

     
        </>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };




  const POPUPACTION = useCallback(
    async (text, data, item) => {
      setbtn(true)
      console.log("Buy is Calling", text, data, item, Network);
      if (accountAddress) {
        var datas = await switchnetwork(Network)
    
        if (datas.status == true) {
          dispatch(datas.data)
          dispatch(datas.currency)
          if (data == "Accept") {
            (async () => {
              let Statu = await ContractCall.GetApproveStatus(
                Tokens_Detail.ContractType == 721 ||
                  Tokens_Detail.ContractType == "721"
                  ? "Single"
                  : "Multiple",
                Tokens_Detail.ContractAddress
              );
              if ((Statu == false) || (Statu == "error")) {
                toast.warn("Need To Approve");
                SetBtnData("open");
                SetOpenPopup(data);
                SetSendDet(item)
              } else {
                SetBtnData("error");
                SetOpenPopup(data);
                SetSendDet(item)
              }
            })();
          } 
          else {
            setText(text)
            SetOpenPopup(data);
            SetSendDet(item);
          }
        } else {
          toast.error(data.msg)
        }
      } else {
        if (data === "Share") {
          setText(text)
          SetOpenPopup(data);
          SetSendDet(item);
        } else
          toast.error(" log in to connect to the wallet ", { autoClose: 1000, closeButton: true, closeOnClick: true });
      }

    },

    [OpenPopup, accountAddress, Tokens_Detail.ContractAddress]
  );

  
  const Explore = async (data, tab) => {
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 4,
      Owner: Owner,
      page: page ?? 1,
      from: "info",
      Contract: Contract,
      Id: Id.toString(),
      MyAdd: accountAddress,
    };
    console.log("Account Address Checing", SendDATA, accountAddress);
    let Resp = await Token_Info_Func(SendDATA);
    console.log("Owners List", Resp?.token);
    if (
      Resp?.token?.success == "success" &&
      Resp?.token?.data[0]?.Current_Owner.length > 0
    ) {
      console.log("TOKENDATA", Resp.token.data[0]);
      if (TabName == "All") {
        SetTokens_Detail(Resp.token.data[0]);
        SetExplore(Resp.Explore.data);
        setMoreprops(Resp.token.data[0].NFTProperties?.length)
        dispatch({
          type: "Network_section",
          Network_section: {
            Network: Resp.token.data[0]?.CollectionNetwork == "BNB" ? config.BNBCHAIN :config.ETHCHAIN,
          },
        })
      }
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list:
            SendDATA.page==1 ? 
            [
             ...(
              TabName == "owner"
              ?  Resp.token.data[0].tokenowners_list
              : TabName == "bid"
                ? Resp.Bid.data 
                : []
                ) 
            ]
            
            :
            [
              ...Tokens[TabName].list,
              ...(
                TabName == "owner"
                ? SendDATA.page == 1 ?  Resp.token.data[0].tokenowners_list : [Tokens[TabName],...Resp.token.data[0].tokenowners_list]
                : TabName == "bid"
                  ?  SendDATA.page == 1 ?  Resp.Bid.data : [Tokens[TabName],...Resp.Bid.data]
                  : []
                  )
                  ,
            ],
            loader:
              Resp.token.Count ==
                Tokens[TabName]?.list?.length + Resp.token.data.length
                ? false
                : true,
            page: Tokens[TabName].page,
            owner:
              TabName == "All"
                ? Resp.token.data[0].Current_Owner.pop()
                : Tokens["All"].owner,
            myowner:
              TabName == "All"
                ? Resp.token.data[0].myowner.pop()
                : Tokens["All"].myowner,
            myBid:Resp?.myBid?.data?.pop(),
            highbid: Resp?.highBid?.data[0],
          },
        },
      });
      console.log("kdgfdfad1111", Resp,SendDATA);
    } else {
      SetTokens({ [TabName]: { loader: true, page: 1, list: [] } });
    }
    setTimeout(() => {
      setLoaderTab(false);
      setLoader(false);
    }, 2000);
  };
  const tabChange = (newValue) => {
    setLoaderTab(true);
    SetTabName(newValue);
  };
 
  const closePop = () => {
    SetOpenPopup("");
  };
  const LikeAction = async () => {
    if (accountAddress) {
      if (likedisable) {
        SetLikeDisable(false)
        var check = await LikeForwardRef.current.hitLike(Tokens_Detail);
        toast.success("you " + check + "d this token", { autoClose: 500, closeButton: true, closeOnClick: true });
        if (check == 'like') {
          // Tokens_Detail?.likecount

          SetTokens_Detail({ ...Tokens_Detail, ...{ likecount: Tokens_Detail.likecount + 1 } })
        }
        else if (check == 'unlike') {
          SetTokens_Detail({ ...Tokens_Detail, ...{ likecount: Tokens_Detail.likecount - 1 } })
        }
        SetLikeDisable(true)
      }

    } else toast.error("Connect Wallet", { autoClose: 1000, closeButton: true, closeOnClick: true });
  };

  function LikeList(data) {
    setLikedTokenList(data);
  }

  var Seemore = () => {
    setMoreprops(Tokens_Detail?.NFTProperties?.length)
  }

 

  return (
    <>
      {
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          LikeList={LikeList}
        />
      }

      {OpenPopup === "Buy" && (
        <BuyNow
        onhide = {()=>{setbtn(false)}}
          owner={SendDet}
          OpenPopup={OpenPopup}
          closePop={closePop}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            
          }}
        />
      )}

      {OpenPopup == "Cancel" && (
        <Cancel
        onhide = {()=>{setbtn(false)}}
          owner={SendDet}
          types="Cancel"
          OpenPopup={OpenPopup}
          closePop={closePop}
          file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
          type={
            Tokens_Detail.CompressedFile
              ? Tokens_Detail.CompressedFile?.includes(".webp")
                ? "image"
                : Tokens_Detail.CompressedFile.includes(".webm")
                  ? "video"
                  : "audio"
              : Tokens_Detail.CompressedFile
          }
          thumb={Tokens_Detail.CompressedThumbFile}
          // noimg={require("../assets/images/No_image.jpg")}
          item={{
            TokenName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
            CoinName: Tokens[TabName]?.myowner?.CoinName,
            NFTCreator : Tokens_Detail?.NFTCreator
          }}
         
        />
      )}
      {OpenPopup === "createorder" && (
        <Lower
        onhide = {()=>{setbtn(false)}}
          text={text}
          owner={SendDet}
          OpenPopup={OpenPopup}
          closePop={closePop}
          file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
          type={
            Tokens_Detail.CompressedFile
              ? Tokens_Detail.CompressedFile?.includes(".webp")
                ? "image"
                : Tokens_Detail.CompressedFile.includes(".webm")
                  ? "video"
                  : "audio"
              : Tokens_Detail.CompressedFile
          }
          thumb={Tokens_Detail.CompressedThumbFile}
          item={{
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            OriginalImage: Tokens_Detail.NFTOrginalImage,
            CompressedFile: Tokens_Detail.CompressedFile,
            CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
            OriginalFile: Tokens_Detail.NFTOrginalImageIpfs,
            NFTCreator: Tokens_Detail.NFTCreator,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTQuantity: Tokens_Detail.NFTQuantity,
            Category: Tokens_Detail.Category,
            NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
            CoinName: Tokens[TabName]?.myowner?.CoinName,
            PutOnSaleType: "FixedPrice",
            PutOnSale: true,
          }}
        />
      )}
      {OpenPopup === "Bid" && (
        <Bid
        onhide = {()=>{setbtn(false)}}
          owner={Tokens[TabName]?.owner}
          bidder={!isEmpty(SendDet) ? SendDet : Tokens[TabName]?.myBid}
          OpenPopup={OpenPopup}
          bid={Tokens[TabName]?.highbid}
          closePop={closePop}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
          }}
        />
      )}
      {console.log('sdgsgsdfgsg', Tokens_Detail)}
      {OpenPopup === "Accept" && (
        <Accept 
        onhide = {()=>{setbtn(false)}}

          owner={Tokens[TabName]?.myowner}
          bidder={SendDet}
          OpenPopup={OpenPopup}
          bid={SendDet}
          closePop={closePop}
          approvestatus={BtnData}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            // CompressedFile:Tokens_Detail.CompressedFile,
            // OriginalFile:Tokens_Detail.OriginalFile,
            // CompressedThumbFile:Tokens_Detail.CompressedThumbFile,
            // OriginalThumbFile:Tokens_Detail.OriginalThumbFile,
          }}
        // file={`${config.IMG_URL}/token/${Tokens_Detail.Creator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
        // type={Tokens_Detail.CompressedFile ? (Tokens_Detail.CompressedFile?.includes('.webp') ? 'image' : Tokens_Detail.CompressedFile.includes('.webm') ? 'video' : 'audio') : Tokens_Detail.CompressedFile}
        // thumb={Tokens_Detail.CompressedThumbFile}
        // noimg={require("../assets/images/No_image.jpg")}
        />
      )}
      {OpenPopup == "CancelBid" && (
        <CancelBids
        onhide = {()=>{setbtn(false)}}
          bidder={SendDet}
          OpenPopup={OpenPopup}
          owner={Tokens[TabName]?.owner}
          closePop={closePop}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
          }}
        />
      )}
      {OpenPopup === "Share" && (
        <Share
          closePop={closePop}
          title={`${Tokens_Detail.NFTName}  NFT`}
          url={`${config.FRONT_URL}/info/${Tokens_Detail.CollectionNetwork}/${Tokens_Detail.ContractAddress}/${SendDet.NFTOwner}/${Tokens_Detail.NFTId}`}
          quote={`${Tokens_Detail.NFTName} NFT`}
        />
      )}


      {
        OpenPopup === "Report" &&
        <Report
          OpenPopup={OpenPopup}
          closePop={closePop}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category
          }}
        />
      }

      <Header />
      {Loader ?
        // <div className='text-center'><div class="lds-hourglass"></div></div>
        <div class="preload preload-container">
          <div class="preload-logo"></div>
        </div>
        : <>
          <div className="infodetail">
            <section className="tf-page-title">
              <div className="tf-container">
                <div className="row">
                  <div className="col-md-12">

                    <ul className="breadcrumbs" data-aos="fade-right">
                      <li><NavLink to="/">Home <MdKeyboardArrowRight/></NavLink></li>
                      <li><NavLink to="/explore">Explore <MdKeyboardArrowRight/></NavLink></li>
                      <li>Product Details</li>
                    </ul>
                    {/* <h4 className="page-title-heading" data-aos="fade-left">Product Details</h4> */}
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* <div>
          <div class="preload-logo"></div>
          </div> */}
          <section className="tf-item-detail kitty__info info">
            <div className="tf-container">
              <div className="row infodeatil">
                <div className="col-md-9">

                  <h2 className="title-detail titledetails kitty__infoTitle">
                    {!isEmpty(InfoDetail)
                      ? InfoDetail?.NFTName?.length > 15 ? <>{InfoDetail?.NFTName.slice(0, 15)}...</> : InfoDetail?.NFTName
                      : Tokens_Detail?.NFTName?.length > 15 ? <>{Tokens_Detail?.NFTName.slice(0, 15)}...</> : Tokens_Detail?.NFTName}
                  </h2>
                  {/* <p className="created pt-2 mb-3"><span>Minted on : </span> <span className="amountss">Sep 30, 2022</span></p> */}

                  <div className="mt-4">
                    <p className="created d-flex flex-column kitty__infoCreator"><span className="kitty__infoLabel spaceMono">Created By : </span>
                      <span className="amountss">
                        {console.log("!isEmpty(InfoDetail)",InfoDetail,!isEmpty(InfoDetail),Tokens[TabName]?.owner)}
                        {InfoDetail &&!isEmpty(InfoDetail) ? (
                          <div className="author d-flex align-items-center jk">
                            <img
                              src={
                                Tokens_Detail?.Profile
                                  && !isEmpty(Tokens_Detail?.Profile) ? `${config.IMG_URL}/user/${Tokens_Detail?.WalletAddress}/profile/${payload?.Profile}`
                                  : config.profile
                              }
                              alt="Image"
                            />
                            <Link to={"/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl}>
                              <h6 className="title">
                                {payload?.DisplayName
                                  ? payload?.DisplayName
                                  : address_showing(payload?.WalletAddress)}
                              </h6>
                            </Link>
                          </div>
                        ) : (
                          <div className="author d-flex align-items-center jk">
                            <img
                              src={
                                Tokens[TabName]?.owner?.Profile
                                  && Tokens[TabName]?.owner?.Profile != "" ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                  : config.profile
                              }
                              alt="Image"
                            />

                            <Link to={Tokens[TabName]?.owner?.CustomUrl ? "/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl : "#"}>
                              <h6 className="title mb-0">
                                {Tokens[TabName]?.owner?.DisplayName
                                  ? Tokens[TabName]?.owner?.DisplayName
                                  : address_showing(
                                    Tokens[TabName]?.owner?.NFTOwner
                                  )}
                              </h6>
                            </Link>
                          </div>
                        )}
                      </span>
                    </p>
                  </div>
                  <p className="created pt-3 mt-4"><span className="created mb-3 kitty__infoLabel spaceMono" >Total available tokens:</span>&nbsp;<span className="amountss">{InfoDetail?.NFTBalance ? InfoDetail?.NFTBalance : Tokens[TabName]?.owner?.NFTBalance}</span></p>
                  {Tokens[TabName]?.owner?.PutOnSaleType === "FixedPrice" && <p className="created"><span>Price:</span>&nbsp;<span className="amountss">{Tokens[TabName]?.owner?.NFTPrice}&nbsp;{Tokens[TabName]?.owner?.CoinName}</span></p>}
                  <div className="mt-4">
                    <p className="created mb-3 mt-4 kitty__infoLabel spaceMono">Description</p>
                    {console.log("tooojjjopo" , Tokens_Detail)}
                    <p className="except mt-3">
                      {isEmpty(InfoDetail) ? Tokens_Detail?.NFTDescription : InfoDetail?.NFTDescription }
                      {/* The Orbitians<br></br>
                      is a collection of 10,000 unique NFTs on the Ethereum blockchain,<br></br><br></br> There are all sorts of beings in the NFT Universe. The most advanced and friendly of the bunch are Orbitians. <br></br><br></br>They live in a metal space machines, high up in the sky and only have one foot on Earth.
                      These Orbitians are a peaceful race, but they have been at war with a group of invaders for many generations. The invaders are called Upside-Downs, because of their inverted bodies that live on the ground, yet do not know any other way to be. Upside-Downs believe that they will be able to win this war if they could only get an eye into Orbitian territory, so they've taken to make human beings their target. */}
                    </p>

               



                  </div>







                  <div className="tf-tab pt-4">

                    <ul className="menu-tab tabsections">
                      <li
                        className={
                          TabName == "All"
                            ? "tab-title active"
                            : "tab-title"
                        }
                        onClick={() => tabChange("All")}
                      >
                        <a>History</a>
                      </li>
                      <li
                        className={
                          TabName == "owner"
                            ? "tab-title active"
                            : "tab-title"
                        }
                        onClick={() => tabChange("owner")}
                      >
                        <a>Owners</a>
                      </li>
                      {isEmpty(InfoDetail) && (
                        <li
                          className={
                            TabName == "bid"
                              ? "tab-title active"
                              : "tab-title"
                          }
                          onClick={() => tabChange("bid")}
                        >
                          <a>Bids</a>
                        </li>
                      )}
                      <li
                        className={
                          TabName == "details"
                            ? "tab-title active"
                            : "tab-title"
                        }
                        onClick={() => tabChange("details")}
                      >
                        <a>Details</a>
                      </li>
                      {/* <li className={TabName == 'history' ? "tab-title active" : "tab-title"}  onClick={() => tabChange('bid')}>
                        <a>History</a>
                    </li> */}
                    </ul>
                    <div className="content-tab">
                      <div
                        className={
                          TabName == "All"
                            ? "content-inner active d-block"
                            : "content-inner d-none"
                        }
                      >
                        <div className="tab-details">
                          <div className="top">
                            <div className="author">
                              <div className="heading">Current Owner</div>{console.log('sdkgjkdjgkdjg', Tokens[TabName]?.owner, !isEmpty
                                (Tokens[TabName]?.owner?.Profile), InfoDetail, Tokens[TabName])}
                              {!isEmpty(InfoDetail) ? (
                                <div className="infor infor_hover_blk">
                                  <img
                                    src={
                                      Tokens[TabName]?.owner
                                        && !isEmpty
                                          (Tokens[TabName]?.owner?.Profile) ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                        : config.profile
                                    }
                                    alt="Image"
                                  />
                                  <Link to={"/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl}>
                                    <h6 className="name mb-0 kitty__infotabLabel">
                                      {payload?.DisplayName
                                        ? payload?.DisplayName
                                        : address_showing(
                                          payload?.WalletAddress
                                        )}
                                    </h6>
                                  </Link>
                                </div>
                              ) : (
                                <div className="infor infor_hover_blk">
                                  <img
                                    src={
                                      Tokens[TabName]?.owner?.Profile
                                        && !isEmpty(Tokens[TabName]?.owner?.Profile) ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                        : config.profile
                                    }
                                    alt="Image"
                                  />
                                  <Link to={Tokens[TabName]?.owner?.CustomUrl ? "/profile" + "/" + Tokens[TabName]?.owner?.CustomUrl : "#"}>
                                    <h6 className="name mb-0 kitty__infotabLabel">
                                      {Tokens[TabName]?.owner?.DisplayName
                                        ? Tokens[TabName]?.owner?.DisplayName
                                        : address_showing(
                                          Tokens[TabName]?.owner?.NFTOwner
                                        )}
                                    </h6>
                                  </Link>
                                </div>
                              )}
                            </div>
                            <div className="author">
                              <div className="heading">Creator</div>
                              {!isEmpty(InfoDetail) ? (
                                <div className="infor infor_hover_blk">
                                  <img
                                    src={
                                      Tokens_Detail?.Creator_Profile
                                        && !isEmpty
                                          (Tokens_Detail?.Creator_Profile) ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Profile}`
                                        : config.profile
                                    }
                                    // Tokens_Detail
                                    alt="Image"
                                  />
                                  {/* <img src={require("../assets/images/author-detail-2.png")} alt="Image" /> */}
                                  <Link to={"/profile" + "/" + Tokens_Detail?.Creator_CustomUrl}>
                                    <h6 className="name mb-0 kitty__infotabLabel">
                                      {Tokens_Detail?.Creator_DisplayName
                                        ? Tokens_Detail?.Creator_DisplayName
                                        : address_showing(
                                          Tokens_Detail?.Creator_WalletAddress
                                        )}
                                    </h6></Link>
                                </div>
                              )
                                :
                                (
                                  <div className="infor infor_hover_blk">
                                    <img
                                      src={
                                        Tokens_Detail?.Creator_Profile
                                          && !isEmpty
                                            (Tokens_Detail?.Creator_Profile) ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Creator_Profile}`
                                          : config.profile
                                      }
                                      // Tokens_Detail
                                      alt="Image"
                                    />
                                    {/* <img src={require("../assets/images/author-detail-2.png")} alt="Image" /> */}
                                    <Link to={"/profile" + "/" + Tokens_Detail?.Creator_CustomUrl}>
                                      <h6 className="name mb-0 kitty__infotabLabel">
                                        {Tokens_Detail?.Creator_DisplayName
                                          ? Tokens_Detail?.Creator_DisplayName
                                          : address_showing(
                                            Tokens_Detail?.NFTCreator
                                          )}
                                      </h6>
                                    </Link>
                                  </div>
                                )}
                            </div>
                          </div>
                          {console.log('proppperrrtiiiesss,', Tokens_Detail?.NFTProperties, Tokens_Detail?.NFTProperties?.length)}
                          {Tokens_Detail?.NFTProperties?.length > 0 &&
                            !isEmpty(Tokens_Detail?.NFTProperties[0]) &&
                            <>
                              <div className="title-propepties">Properties</div>
                              <ul className="properties">
                                {Tokens_Detail?.NFTProperties?.map((val, ind) =>
                                  <li>
                                    <svg
                                      width="12"
                                      height="14"
                                      viewBox="0 0 12 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 11.375L1.09375 7.53125L0 8.375L6 13.0312L12 8.375L10.9062 7.53125L6 11.375ZM6 9.65625L10.9062 5.84375L12 5L6 0.34375L0 5L1.09375 5.84375L6 9.65625ZM6 2.03125L9.8125 5L6 7.96875L2.1875 5L6 2.03125Z"
                                        fill="white"
                                      />
                                    </svg>
                                    {Object.keys(val)[0]?.length > 15 ? <>{Object.keys(val)[0]?.slice(0, 15)}...</> : Object.keys(val)[0]} : {Object.values(val)[0].length > 15 ? <>{Object.values(val)[0].slice(0, 15)}...</> : Object.values(val)[0]}
                                  </li>

                                )}
                                {(moreprops > 6 && moreprops <= 9) && <li className="active" onClick={Seemore()}>See more</li>}
                              </ul>
                            </>}

                        </div>
                      </div>

                      <div
                        className={
                          TabName == "owner"
                            ? "content-inner active d-block"
                            : "content-inner d-none"
                        }
                      >
                        <ul className="tab-bid tab_bid_height">
                          {!isEmpty(InfoDetail) ? (
                            <li className="mt-4">
                              <div className="box-bid">
                              
                                <div className="infor infor_hover_blk">
                                <div className="image-bid">
                                  <img
                                    src={
                                      Tokens[TabName]?.owner?.Profile
                                        && !isEmpty(Tokens[TabName]?.owner?.Profile) ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                        : config.profile
                                    }
                                    alt="Image"
                                  />
                                </div>
                                  <div className="history">
                                    <span className="name">
                                      {payload?.DisplayName
                                        ? payload?.DisplayName
                                        : address_showing(
                                          payload?.WalletAddress
                                        )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ) : (
                            Tokens["owner"]?.list?.map((data, key) => {
                              return (
                                <li>
                                  <div className="box-bid">
                                  
                                    <div className="infor infor_hover_blk">
                                    <div className="image-bid">
                                      <img
                                        src={
                                          data?.Profile
                                            && !isEmpty(data?.Profile) ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                            : config.profile
                                        }
                                        alt="Image"
                                      />
                                    </div>
                                      <div className="history">
                                        {
                                          // (data.PutOnSaleType ==
                                          //   "FixedPrice" ||
                                          //   data.PutOnSaleType ==
                                          //     "TimedAuction") &&
                                          (
                                            <span className="price">
                                              {console.log("sdgsgggggsinproice",Tokens_Detail, data)}
                                              { data.PutOnSaleType != "UnlimitedAuction" && Tokens_Detail.NFTCreator === data.NFTOwner ?
                                                <>{data.NFTPrice} {data.CoinName} </> :
                                                <>{data?.buyprice?.NFTPrice} {data?.buyprice?.CoinName}</>
                                              }
                                              {" "}
                                              by
                                            </span>
                                          )}{" "}
                                        {" "}
                                        <span className="name">
                                          {data?.DisplayName
                                            ? data?.DisplayName
                                            : address_showing(
                                              data?.NFTOwner
                                            )}

<div className="time">
                                        {moment(data.updatedAt).fromNow()}
                                      </div>
                                        </span>
                                      </div>
                                     
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                      {console.log("totkoktkokeote", Tokens["bid"]?.list,Tokens[TabName]?.owner?.WalletAddress,Tokens[TabName])}
                      <div
                        className={
                          TabName == "bid"
                            ? "content-inner active d-block"
                            : "content-inner d-none"
                        }
                      >
                        <ul className="tab-bid tab_bid_height">
                          {(Tokens["bid"]?.list?.length > 0 ||  Tokens["bid"]?.list?.filter((val)=> val.WalletAddress != Tokens[TabName]?.owner?.NFTOwner).length > 0 )?
                            Tokens["bid"]?.list?.filter((val)=> val.WalletAddress != Tokens[TabName]?.owner?.NFTOwner).map((data, key) => {
                              return (
                                <>
                                <li className="mt-4">
                                  <div className="box-bid">
                                
                                    <div className="infor infor_hover_blk">
                                    <div className="image-bid">
                                      <img
                                        src={
                                          data?.Profile
                                            && !isEmpty(data?.Profile) ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                            : config.profile
                                        }
                                        alt="Image"
                                      />
                                    </div>
                                      <div className="history">
                                        {
                                          <span className="price">
                                            {data.TokenBidAmt} {data.CoinName}
                                            {" "}
                                            by
                                          </span>
                                        }{" "}
                                        {/* by{" "} */}
                                        {console.log("fff", data.WalletAddress !=
                                          Tokens["bid"]?.myowner?.WalletAddress &&
                                          Tokens["bid"]?.myowner?.WalletAddress ==
                                          accountAddress &&
                                          new Date(
                                            Tokens[TabName]?.myowner?.EndClockTime
                                          ).getTime() < Date.now())}
                                        <span className="name">
                                          {data?.DisplayName
                                            ? data?.DisplayName
                                            : address_showing(
                                              data?.TokenBidderAddress
                                            )}
                                               <div className="time">
                                        Bid for : {data?.Pending} Token
                                      </div>
                                      <div className="time">
                                        {moment(data.updatedAt).fromNow()}
                                      </div>
                                        </span>
                                      </div>
                                   
                                    </div>
                                  </div>
                                  {data.WalletAddress == accountAddress && (
                                    <>
                                      <Button
                                          disabled={btn}
                                        className="tf-button kitty__loadMoreBtn edit_offer primary"
                                        disableRipple
                                        onClick={() =>{
                                          
                                          POPUPACTION("dummy", "Bid", data)

                                        }
                                        }
                                      >
                                        Edit Offer
                                      </Button>
                                      <Button
                                          disabled={btn}
                                        className="tf-button kitty__loadMoreBtn edit_offer secondary"
                                        disableRipple
                                        onClick={() =>{
                                          
                                          POPUPACTION("dummy", "CancelBid", data)

                                        }
                                        }
                                      >
                                        Cancel Offer
                                      </Button>
                                    </>
                                  )}
                                  {data.WalletAddress !=
                                    Tokens["bid"]?.myowner?.WalletAddress &&
                                    Tokens["bid"]?.myowner?.WalletAddress ==
                                    accountAddress &&
                                    new Date(
                                      Tokens[TabName]?.myowner?.EndClockTime
                                    ).getTime() < Date.now() &&
                                    (
                                      <Button
                                      disabled={btn}
                                        className="tf-button kitty__loadMoreBtn edit_offer primary"
                                        disableRipple
                                        onClick={() =>{
                                     
                                          POPUPACTION("dummy", "Accept", data)

                                        }
                                        }
                                      >
                                        Accept Offer
                                      </Button>
                                    )}
                                </li>
                                </>
                              );
                            })
                            :
                            <h6 className="available_tokens"><span>No Bids Yet</span></h6>
                          }
                        </ul>
                      </div>
                      {console.log("myowner111111", InfoDetail,Tokens_Detail)}
                      <div
                        className={
                          TabName == "details"
                            ? "content-inner active d-block"
                            : "content-inner d-none"
                        }
                      >

                        <div className="infodetail_label">
                          {console.log("akakkakakakaka", `${config.IPFS}${!isEmpty(Tokens_Detail) ? Tokens_Detail?.NFTOrginalImageIpfs : InfoDetail?.NFTOrginalImageIpfs}`, InfoDetail?.NFTOrginalImageIpfs,InfoDetail)}
                          <label className="info_detail_lables">Contract Address :</label>
                          <div className="copy-btn-icon">
                            <a className="text_acoloring" href={`${config.Block_URL[!isEmpty(Tokens_Detail) ?Tokens_Detail.CollectionNetwork : InfoDetail.CollectionNetwork]}address/${!isEmpty(Tokens_Detail) ? Tokens_Detail.ContractAddress : InfoDetail.ContractAddress}`} target="_blank"
                              rel="noreferrer">
                              <p className="info_detail_values">{!isEmpty(Tokens_Detail) ? Tokens_Detail.ContractAddress : InfoDetail.ContractAddress}</p>

                            </a>
                            <CopyToClipboard
                              text={!isEmpty(Tokens_Detail) ? Tokens_Detail.ContractAddress : InfoDetail.ContractAddress}
                              onCopy={() =>
                                toast.success("Contract Address Copied", { autoClose: 1000 })
                              } >
                              <span className="copy_btn_span"> <i class="fa-regular fa-copy" ></i></span>
                            </CopyToClipboard>

                          </div>
                        </div>

                        <div className="infodetail_label">
                          <label className="info_detail_lables">Token ID :</label>
                          <div className="copy-btn-icon">
                            <p className="info_detail_values">{!isEmpty(Tokens_Detail) ? Tokens_Detail.NFTId : InfoDetail.NFTId}</p>
                            <CopyToClipboard
                              text={!isEmpty(Tokens_Detail) ? Tokens_Detail.NFTId : InfoDetail.NFTId}
                              onCopy={() =>
                                toast.success("Token ID Copied", { autoClose: 1000 })
                              } >
                              <span className="copy_btn_span"><i class="fa-regular fa-copy"></i></span>
                            </CopyToClipboard>
                          </div>
                        </div>
                        {console.log("STANDERD",Tokens_Detail,InfoDetail.ContractType,Tokens_Detail?.NFTOrginalImageIpfs)}
                        <div className="infodetail_label">
                          <label className="info_detail_lables">Token Standard :</label>
                          <p className="info_detail_values">{!isEmpty(Tokens_Detail) ? Tokens_Detail.ContractType == "721" ?  Tokens_Detail.CollectionNetwork == "ETH" ? "ERC 721" : "BEP 721 " :  Tokens_Detail.CollectionNetwork == "ETH" ?  "ERC 1155"  : "BEP 1155" :  InfoDetail.ContractType == "721" ? Tokens_Detail.CollectionNetwork == "ETH" ? "ERC 721" : "BEP 721 " : Tokens_Detail.CollectionNetwork == "ETH" ? "ERC 1155"  : "BEP 1155"}</p>
                        </div>
                        <div className="infodetail_label">
                          <label className="info_detail_lables">Blockchain :</label>.
                          <p className="info_detail_values">{Tokens_Detail.CollectionNetwork == "ETH" ? "ETH Chain" : "BNB Chain"}</p>
                        </div>
                        <div className="infodetail_label">
                          <a
                            href={ isEmpty(Tokens_Detail?.NFTOrginalImageIpfs) ? Tokens_Detail?.CompressedFile :  `${config.IPFS}${!isEmpty(Tokens_Detail) ? Tokens_Detail?.NFTOrginalImageIpfs : InfoDetail?.NFTOrginalImageIpfs}`}
                            target="_blank"
                            rel="noreferrer">
                            <label className="info_detail_lables">View on IPFS  </label>
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>


                        </div>
                        {(!isEmpty(Tokens_Detail) || !isEmpty(InfoDetail)) &&
                          <div className="infodetail_label">
                            <a
                              href={`${config.Block_URL[!isEmpty(Tokens_Detail) ?Tokens_Detail.CollectionNetwork : InfoDetail.CollectionNetwork]}tx/${!isEmpty(Tokens_Detail) ? Tokens_Detail?.tokenowners_list[0]?.HashValue : InfoDetail?.HashValue}`}
                              target="_blank"
                              rel="noreferrer">
                              <label className="info_detail_lables">View on Scan  </label>
                              <i class="fa-solid fa-arrow-up-right-from-square"></i>
                            </a>


                          </div>}
                      </div>
                    </div>
                  </div>


                  <div className="content-bottom">
                    {console.log("Tokens[TabName].CollectionNetwork",Tokens[TabName])}
                          {!isEmpty(Tokens[TabName]?.highbid) && (
                            <div className="heading topbidflex">
                              <h6>Top bid</h6>
                              <div className="price topbidflex">
                                <div className="icon">
                                <img src={Tokens_Detail.CollectionNetwork == "BNB" ? Binance : Ethereum} alt="Image" className="card_image mr-2" width={20} />
                                  {/* <i className="fab fa-ethereum"></i> */}
                                </div>
                                <span className="ml-3">
                                  {Tokens[TabName]?.highbid?.TokenBidAmt}{" "}
                                  {Tokens[TabName]?.highbid?.CoinName}
                                </span>
                              </div>
                              {Tokens[TabName]?.highbid?.WalletAddress !=
                                accountAddress &&
                                Tokens[TabName]?.owner?.WalletAddress ==
                                  accountAddress &&
                                Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "TimedAuction" &&
                                new Date(
                                  Tokens[TabName]?.myowner?.EndClockTime
                                ).getTime() < Date.now() && (
                                  <Button
                                    className="tf-button kitty__loadMoreBtn"
                                    disabled={btn}
                                    onClick={() =>
                                      POPUPACTION("dummy",
                                        "Accept",
                                        Tokens[TabName]?.highbid
                                      )
                                    }
                                  >
                                    Accept
                                  </Button>
                                )}
                            </div>
                          )}
                          <div className="button topbidbtn">
                            {!isEmpty(InfoDetail) && (
                              <Button
                                className="tf-button kitty__loadMoreBtn"
                                onClick={() =>
                                  push(`/list/${payload?.WalletAddress}/${Id}`, {
                                    state: InfoDetail,
                                  })
                                }
                              >
                                List on Marketplace
                              </Button>
                            )}
                            {isEmpty(InfoDetail) &&
                              (Tokens_Detail?.ContractType?.toString() ===
                              "721" ? (
                                Tokens[TabName]?.myowner?.WalletAddress ==
                                accountAddress ? (
                                  Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "FixedPrice" ? (
                                    <Button
                                    disabled={btn}
                                      className="tf-button kitty__loadMoreBtn"
                                      onClick={() =>{
                                      
                                        POPUPACTION("dummy",
                                          "Cancel",
                                          Tokens[TabName]?.myowner
                                        )}
                                      }
                                    >
                                      Cancel Now
                                    </Button>
                                  ) : Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "NotForSale" ||
                                    Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "UnlimitedAuction" ||
                                    (Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "TimedAuction" &&
                                      new Date(
                                        Tokens[TabName]?.myowner.EndClockTime
                                      ).getTime() < Date.now()) ? (
                                    <Button
                                    disabled={btn}
                                      className="tf-button kitty__loadMoreBtn"
                                      onClick={() =>{
                                        //diable the button once clicked
                                      

                                        POPUPACTION("dummy", 
                                          "createorder",
                                          Tokens[TabName]?.myowner
                                        )
                                      }
                                      }
                                    >
                                      Put on Sale
                                    </Button>
                                  ) : (
                                    Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "TimedAuction" &&
                                      new Date(Tokens[TabName]?.myowner?.ClockTime) > Date.now() ?
                                
                                    ""
                                    :
                                    ""
                                  
                                  )
                                ) : (
                                  Tokens[TabName]?.owner &&
                                  Tokens[TabName]?.owner?.WalletAddress !=
                                    accountAddress &&
                                  (Tokens[TabName]?.owner?.PutOnSaleType ==
                                  "FixedPrice" ? (
                                    <Button
                                      className="tf-button kitty__loadMoreBtn mr-3"
                                      disabled={btn}
                                      onClick={() =>{
                                       
                                        POPUPACTION("dummy","Buy", Tokens[TabName]?.owner)}
                                      }
                                    >
                                      Buy Now
                                    </Button>
                                  ) : 
                                  ""
                                  // (
                                  //   Tokens[TabName]?.myBid?.WalletAddress ==
                                  //     accountAddress && (
                                  //     <Button
                                  //       className="tf-button mr-3"
                                  //       onClick={() =>
                                  //         POPUPACTION("dummy","CancelBid", Tokens[TabName]?.myBid)
                                  //       }
                                  //     >{console.log('biiiddds',Tokens[TabName],Tokens[TabName]?.myBid)}
                                  //       Cancel Bid
                                  //     </Button>
                                  //   )
                                  // )
                                  )
                                )
                              ) : Tokens[TabName]?.myowner?.WalletAddress ==
                                Tokens[TabName]?.owner?.WalletAddress ? (
                                <>
                                  {Tokens[TabName]?.myowner?.PutOnSaleType ==
                                    "FixedPrice" && (
                                    <Button
                                      className="tf-button kitty__loadMoreBtn"
                                      disabled={btn}
                                      onClick={() =>{
                                        
                                        POPUPACTION("dummy",
                                          "Cancel",
                                          Tokens[TabName]?.myowner
                                        )}
                                      }
                                    >
                                      Cancel Now
                                    </Button>
                                  )}
                                  {Tokens[TabName]?.myBid?.WalletAddress ==
                                  accountAddress ? 
                                  ""
                              
                                  :
                                  ""
                                
                                  }
                                </>
                              ) : Tokens[TabName]?.owner?.PutOnSaleType ===
                                "FixedPrice" ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn"
                                  disabled={btn}
                                  onClick={() =>{
                                   
                                    POPUPACTION("dummy","Buy", Tokens[TabName].owner)
                                  }}
                                >
                                  Buy Now
                                </Button>
                              ) : 
                              ""
                         
                              )}
  
                            {isEmpty(InfoDetail) &&
                              (Tokens_Detail?.ContractType?.toString() ===
                              "721" ? (
                                Tokens[TabName]?.myowner?.WalletAddress ==
                                accountAddress ? (
                                  Tokens[TabName]?.myowner?.PutOnSaleType ==
                                    "FixedPrice" && (
                                    <Button
                                      className="tf-button kitty__loadMoreBtn ml-3"
                                      disabled={btn}
                                      onClick={() =>
                                        POPUPACTION( "Change Price",
                                          "createorder",
                                          Tokens[TabName]?.myowner
                                        )
                                      }
                                    >
                                      Change Price
                                    </Button>
                                  )
                                ) : (
                                  Tokens[TabName]?.owner?.WalletAddress !=
                                    accountAddress &&
                                  (Tokens[TabName]?.owner?.PutOnSaleType ==
                                    "TimedAuction" &&
                                  new Date(Tokens[TabName].owner.EndClockTime)?.getTime() < Date.now() ? (
                                    <a href="#" className="tf-button kitty__loadMoreBtn">
                                      Auction End
                                    </a>
                                  ) : Tokens[TabName]?.highbid?.WalletAddress !=
                                      accountAddress &&
                                    Tokens[TabName]?.owner?.WalletAddress ==
                                      accountAddress ? (
                                    <Button
                                      className="tf-button kitty__loadMoreBtn  ml-3"
                                      disabled={btn}
                                      onClick={() =>{
                                     
                                        POPUPACTION("dummy",
                                        "Accept",
                                        Tokens[TabName]?.highbid
                                      )

                                      }
                                     
                                      }
                                    >
                                      Accept
                                    </Button>
                                  ) : Tokens[TabName]?.myBid?.WalletAddress ==
                                    accountAddress ?
                                    "" 
                                  //   (
                                  //   <Button
                                  //     className="tf-button"
                                  //     onClick={() =>
                                  //       POPUPACTION("dummy","Bid", Tokens[TabName]?.myBid)
                                  //     }
                                  //   >
                                  //     Edit Bid
                                  //   </Button>
                                  // ) 
                                  : ((new Date(Tokens["All"]?.owner?.EndClockTime) > Date.now() &&
                                        new Date(Tokens["All"]?.owner?.ClockTime) > Date.now()) ?
                                      //   <Button
                                      //   className="tf-button"
                                      // >
                                      //   Not Started Yet
                                      // </Button>
                                      ""
                                    :
                                    ""
                                    // <Button
                                    //   className="tf-button"
                                    //   onClick={() => POPUPACTION("dummy ","Bid", {})}
                                    // >
                                    //   Bid now
                                    // </Button>
                                  ))
                                )
                              ) : Tokens[TabName]?.myowner?.WalletAddress ==
                                Tokens[TabName]?.owner?.WalletAddress ? (
                                Tokens[TabName]?.owner?.PutOnSaleType ==
                                "FixedPrice" ? (
                                  <Button
                                    className="tf-button kitty__loadMoreBtn  ml-3"
                                    disabled={btn}
                                    onClick={() =>
                                      POPUPACTION("Change Price",
                                        "createorder",
                                        Tokens[TabName]?.myowner
                                      )
                                    }
                                  >
                                    Change Price
                                  </Button>
                                ) : (
                                  <Button
                                    className="tf-button kitty__loadMoreBtn  ml-3"
                                    disabled={btn}
                                    onClick={() =>{
                                     
                                      POPUPACTION("dummy",
                                        "createorder",
                                        Tokens[TabName]?.myowner
                                      )}
                                    }
                                  >
                                    Put on Sale
                                  </Button>
                                )
                              ) : Tokens[TabName]?.owner?.WalletAddress !=
                                  accountAddress &&
                                Tokens[TabName]?.highbid?.WalletAddress !=
                                  accountAddress &&
                                Tokens[TabName]?.owner?.WalletAddress ==
                                  accountAddress ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn  ml-3"
                                  disabled={btn}
                                  onClick={() =>
                                    POPUPACTION("dummy",
                                      "Accept",
                                      Tokens[TabName]?.highbid
                                    )
                                  }
                                >
                                  Accept
                                </Button>
                              ) : Tokens[TabName]?.myBid?.WalletAddress ==
                              accountAddress ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn ml-3"
                                  disabled={btn}
                                  onClick={() =>
                                    POPUPACTION("dummy","Bid", Tokens[TabName]?.myBid)
                                  }
                                >
                                  Edit Bid
                                </Button>
                              ) : (
                                <Button
                                  className="tf-button kitty__loadMoreBtn  ml-3"
                                  disabled={btn}
                                  onClick={() => POPUPACTION(" dummy","Bid", {})}
                                >
                                  Bid Now{" "}
                                </Button>
                              ))}
  
                            {/*                                                                                                         
                                                      <a href="#" className="tf-button" data-toggle="modal" data-target="#popup_bid">Place your bid</a>
                                                      
                                                      <a href="#" className="tf-button">Buy Now</a> */}
                          </div>

                        </div>
{
console.log("Tokens[TabName]",Tokens[TabName])
}
                </div>

                <div className="col-md-3  lk">
                  <div className="tf-item-detail-inner">
                    {/* <div className="content">
                      <div className="content-top">
                        {isEmpty(InfoDetail) && (
                          <div className="wishlish">
                            <div
                              className="number-wishlish"
                              onClick={() => LikeAction()}

                            >
                              {(LikedTokenList?.some(
                                (value) => value.NFTId === Tokens_Detail.NFTId
                              ) ? (
                                <i className="fa fa-heart active"></i>) : <i className="fa fa-heart"></i>)}{Tokens_Detail?.likecount}
                            </div>

                            <div className="option btn-option">
                              <i className="far fa-ellipsis-h"></i>
                              {console.log("wallwllawaw",)}
                              <div className="option_popup">
                                {Tokens[TabName]?.owner?.NFTOwner != accountAddress && <p onClick={() => POPUPACTION("dummy", 'Report', Tokens[TabName]?.owner)}>Report</p>}
                                <p onClick={() => POPUPACTION("dummy", 'Share', Tokens[TabName]?.owner)}>Share</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>
                  <div class="card">
                    {console.log("Token detailssssss", Tokens_Detail)}
                    {/* <img class="card-img-top" src={author1} alt="Card image cap"/> */}
                    <div className="image">
                    { console.log("Tokens_Detailssssss",Tokens_Detail)}

                      {console.log('sfhgdfhdf', InfoDetail, )}
                      {!isEmpty(InfoDetail) ? (
                        InfoDetail?.CompressedFile?.includes(".mp3") ?
                          // <ImgAudVideo
                          //   file={InfoDetail.metadata.animation_url}
                          //   type={"audio"}
                          //   thumb={InfoDetail.Image}
                          //   from="info"
                          //   origFile={InfoDetail.metadata.animation_url}
                          // />
                          <ImgAudVideo
                            file={InfoDetail?.CompressedFile.split(':')[0] == 'https' ? InfoDetail?.CompressedFile : `${config.IMG_URL}/nft/${InfoDetail?.NFTCreator}/Compressed/NFT/${InfoDetail?.CompressedFile}`}
                            origFile={InfoDetail?.NFTOrginalImage.split(':')[0] == 'https' ? InfoDetail?.NFTOrginalImage : `${config.IMG_URL}/nft/${InfoDetail?.NFTCreator}/Original/NFT/${InfoDetail?.NFTOrginalImage}`}
                            thumb={`${config.IMG_URL}/nft/${InfoDetail.NFTCreator}/Compressed/NFT_THUMB/${InfoDetail?.CompressedThumbFile}`}
                            type={
                              InfoDetail?.CompressedFile
                                ? InfoDetail?.CompressedFile?.includes(".webp") || InfoDetail?.CompressedFile?.includes(".png")
                                  ? "image"
                                  : InfoDetail?.CompressedFile.includes(".webm")
                                    ? "video"
                                    : "audio"
                                : InfoDetail?.CompressedFile
                            }
                          />
                          :
                          <iframe style={{
                            background: 'url(' + InfoDetail?.Image + ')', backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 100%", maxHeight: 288, minHeight: 288, minWidth: "100%", maxWidth: "100%", borderRadius: 15
                          }} height="288" width="288" title="Iframe Example" id="myiFrame"></iframe>
                      ) : (
                        
                        <ImgAudVideo
                          file={Tokens_Detail?.NFTOrginalImage === undefined || null ? "" : Tokens_Detail?.NFTOrginalImage.split(':')[0] == 'https' ? Tokens_Detail?.NFTOrginalImage : `${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail?.NFTOrginalImage}`}
                          type={
                            Tokens_Detail.CompressedFile
                              ? Tokens_Detail.CompressedFile?.includes(".webp") || Tokens_Detail.CompressedFile?.includes(".png")
                                ? "image"
                                : Tokens_Detail.CompressedFile.includes(".webm")
                                  ? "video"
                                  : "audio"
                              : Tokens_Detail.CompressedFile
                          }
                          thumb={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT_THUMB/${Tokens_Detail.CompressedThumbFile}`}
                          from="info"
                          origFile={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail.OriginalFile}`}
                        />


                      )}
                      {/* <img src={require("../assets/images/banner-new.jpg")} alt="Image" /> */}
                    </div>
                    <div class="card-body">
                      <div>
                        {/* <p className="cardtitle">Auction ends in:</p> */}
{/* { console.log("TIMEDAUCTIONNNSSS",Tokens["All"]?.owner?.ClockTime,Tokens["All"]?.owner?.EndClockTime,new Date(Tokens["All"]?.owner?.ClockTime) > Date.now(),new Date(Tokens["All"]?.owner?.ClockTime),Date.now() )} */}
                        {Tokens["All"]?.owner?.PutOnSaleType ===
                          "TimedAuction" &&
                          <div className="featured-countdown mb-2 justify-content-between align-items-center auctionbid">
                            {new Date(Tokens["All"]?.owner?.ClockTime) > Date.now() ? (
                             
                              <span>Auction Yet to Start {Math.ceil((new Date(Tokens["All"]?.owner.ClockTime).getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24))} Days..!</span>
                            )
                              :
                              ((new Date(Tokens["All"]?.owner?.EndClockTime) > Date.now()) ?
                                <>

                                  <span className="ml-1 js-countdown">
                                    {new Date(
                                      Tokens["All"]?.owner?.EndClockTime
                                    ) > Date.now() && (
                                        <Countdown
                                          date={Tokens["All"]?.owner?.EndClockTime}
                                          // autoStart={true}
                                          renderer={renderer}
                                        />
                                      )}
                                  </span>
                                </>
                                :
                                <span>Auction Ended</span>
                              )}
                          </div>
                        }
                      </div>

                      <div className="button try">
                        {/* {!isEmpty(InfoDetail) && (
                          <Button
                            className="tf-button"
                            onClick={() => {
                              push(`/list/${payload?.WalletAddress}/${Id}`, {
                                state: InfoDetail,
                              })
                            }
                            }
                          >
                            List on Marketplace
                          </Button>
                        )} */}
                        {isEmpty(InfoDetail) &&
                          (Tokens_Detail?.ContractType?.toString() ===
                            "721" ? (
                            Tokens[TabName]?.myowner?.WalletAddress ==
                              accountAddress ? (
                              Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "FixedPrice" ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn"
                                  disabled={btn}
                                  onClick={() =>
                                    POPUPACTION("dummy",
                                      "Cancel",
                                      Tokens[TabName]?.myowner
                                    )
                                  }
                                >
                                  Cancel Now
                                </Button>
                              ) : Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "NotForSale" ||
                                Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "UnlimitedAuction" ||
                                (Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "TimedAuction" &&
                                  new Date(
                                    Tokens[TabName]?.myowner.EndClockTime
                                  ).getTime() < Date.now()) ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn"
                                  disabled={btn}
                                  onClick={() =>{
                                    
                                    POPUPACTION("dummy",
                                      "createorder",
                                      Tokens[TabName]?.myowner
                                    )}
                                  }
                                >
                                  Put on Sale
                                </Button>
                              ) : (
                                Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "TimedAuction" &&
                                  new Date(Tokens[TabName]?.myowner?.ClockTime) > Date.now() ? (
                                  <a href="#" className="tf-button kitty__loadMoreBtn">
                                    {/* new Date(Tokens[TabName]?.myowner?.EndClockTime).getTime() > Date.now() */}
                                    Auction Not Started Yet
                                  </a>
                                ) :
                                  (new Date(Tokens[TabName]?.myowner?.EndClockTime).getTime() > Date.now()
                                    && <a href="#" className="tf-button kitty__loadMoreBtn text-center">
                                      Auction is Live Now
                                    </a>)
                              )
                            ) : (
                              Tokens[TabName]?.owner &&
                              Tokens[TabName]?.owner?.WalletAddress !=
                              accountAddress &&
                              (Tokens[TabName]?.owner?.PutOnSaleType ==
                                "FixedPrice" ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn"
                                  disabled={btn}
                                  onClick={() =>
                                    POPUPACTION("dummy", "Buy", Tokens[TabName]?.owner)
                                  }
                                >
                                  Buy Now
                                </Button>
                              ) : (
                                Tokens[TabName]?.myBid?.WalletAddress ==
                                accountAddress && (
                                  <Button
                                    className="tf-button kitty__loadMoreBtn"
                                    disabled={btn}
                                    onClick={() =>
                                      POPUPACTION("dummy", "CancelBid", Tokens[TabName]?.myBid)
                                    }
                                  >{console.log('biiiddds', Tokens[TabName], Tokens[TabName]?.myBid)}
                                    Cancel Bid
                                  </Button>
                                )
                              ))
                            )
                          ) : Tokens[TabName]?.myowner?.WalletAddress ==
                            Tokens[TabName]?.owner?.WalletAddress ? (
                            <>
                              {Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "FixedPrice" && (
                                  <Button
                                    className="tf-button kitty__loadMoreBtn"
                                    disabled={btn}
                                    onClick={() =>
                                      POPUPACTION("dummy",
                                        "Cancel",
                                        Tokens[TabName]?.myowner
                                      )
                                    }
                                  >
                                    Cancel Now
                                  </Button>
                                )}
                              {Tokens[TabName]?.myBid?.WalletAddress ==
                                accountAddress ? (
                                <Button
                                disabled={btn}
                                  className="tf-button kitty__loadMoreBtn"
                                  onClick={() =>
                                    POPUPACTION("dummy", "Bid", Tokens[TabName]?.myBid)
                                  }
                                >
                                  Edit Bid
                                </Button>
                              ) : (
                                Tokens[TabName]?.myowner?.WalletAddress !=
                                Tokens[TabName]?.owner?.WalletAddress && (
                                  <Button
                                  disabled={btn}
                                    className="tf-button kitty__loadMoreBtn"
                                    onClick={() => POPUPACTION("dummy", "Bid", {})}
                                  >
                                    Bid Now
                                  </Button>
                                )
                              )}
                            </>
                          ) : Tokens[TabName]?.owner?.PutOnSaleType ===
                            "FixedPrice" ? (
                            <Button
                              className="tf-button kitty__loadMoreBtn"
                              disabled={btn}
                              onClick={() =>
                                POPUPACTION("dummy", "Buy", Tokens[TabName].owner)
                              }
                            >
                              Buy Now
                            </Button>
                          ) : (
                            Tokens[TabName]?.myBid?.WalletAddress ==
                            accountAddress && (
                              <Button
                                className="tf-button kitty__loadMoreBtn"
                                disabled={btn}
                                onClick={() => POPUPACTION("dummy", "CancelBid", Tokens[TabName]?.myBid)}
                              >
                                Cancel Bid
                              </Button>
                            )
                          ))}

                        {isEmpty(InfoDetail) &&
                          (Tokens_Detail?.ContractType?.toString() ===
                            "721" ? (
                            Tokens[TabName]?.myowner?.WalletAddress ==
                              accountAddress ? (
                              Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "FixedPrice" && (
                                <Button
                                  className="tf-button kitty__loadMoreBtn"
                                  disabled={btn}
                                  onClick={() =>
                                    POPUPACTION("Change Price",
                                      "createorder",
                                      Tokens[TabName]?.myowner
                                    )
                                  }
                                >
                                  Change Price
                                </Button>
                              )
                            ) : (
                              Tokens[TabName]?.owner?.WalletAddress !=
                              accountAddress &&
                              (Tokens[TabName]?.owner?.PutOnSaleType ==
                                "TimedAuction" &&
                                new Date(Tokens[TabName].owner.EndClockTime)?.getTime() < Date.now() ? (
                                <a href="#" className="tf-button kitty__loadMoreBtn">
                                  Auction End
                                </a>
                              ) : Tokens[TabName]?.highbid?.WalletAddress !=
                                accountAddress &&
                                Tokens[TabName]?.owner?.WalletAddress ==
                                accountAddress ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn"
                                  onClick={() =>
                                    POPUPACTION("dummy",
                                      "Accept",
                                      Tokens[TabName]?.highbid
                                    )
                                  }
                                >
                                  Accept
                                </Button>
                              ) : Tokens[TabName]?.myBid?.WalletAddress ==
                              accountAddress ? (
                                <Button
                                  className="tf-button kitty__loadMoreBtn"
                                  
                                  disabled={btn}
                                  onClick={() =>
                                    POPUPACTION("dummy", "Bid", Tokens[TabName]?.myBid)
                                  }
                                >
                                  Edit Bid
                                </Button>
                              ) : ((new Date(Tokens["All"]?.owner?.EndClockTime) > Date.now() &&
                                new Date(Tokens["All"]?.owner?.ClockTime) > Date.now()) ?
                                <Button
                                
                                  className="tf-button kitty__loadMoreBtn"
                                >
                                  Not Started Yet
                                </Button>
                                : <Button
                                disabled={btn}
                                  className="tf-button kitty__loadMoreBtn"
                                  onClick={() => POPUPACTION("dummy", "Bid", {})}
                                >
                                  Bid Now
                                </Button>
                              ))
                            )
                          ) : Tokens[TabName]?.myowner?.WalletAddress ==
                            Tokens[TabName]?.owner?.WalletAddress ? (
                            Tokens[TabName]?.owner?.PutOnSaleType ==
                              "FixedPrice" ? (
                              <Button
                                className="tf-button kitty__loadMoreBtn"
                                disabled={btn}
                                onClick={() =>
                                  POPUPACTION("Change Price",
                                    "createorder",
                                    Tokens[TabName]?.myowner
                                  )
                                }
                              >
                                Change Price
                              </Button>
                            ) : (
                              <Button
                                className="tf-button kitty__loadMoreBtn"
                                disabled={btn}
                                onClick={() =>{
                                  
                                  POPUPACTION("dummy",
                                    "createorder",
                                    Tokens[TabName]?.myowner
                                  )}
                                }
                              >
                                Put on Sale
                              </Button>
                            )
                          ) : Tokens[TabName]?.owner?.WalletAddress !=
                            accountAddress &&
                            Tokens[TabName]?.highbid?.WalletAddress !=
                            accountAddress &&
                            Tokens[TabName]?.owner?.WalletAddress ==
                            accountAddress ? (
                            <Button
                              className="tf-button kitty__loadMoreBtn"
                              disabled={btn}
                              onClick={() =>
                                POPUPACTION("dummy",
                                  "Accept",
                                  Tokens[TabName]?.highbid
                                )
                              }
                            >
                              Accept
                            </Button>
                          ) : Tokens[TabName]?.myBid?.WalletAddress ==
                          accountAddress ? (
                            <Button
                              className="tf-button kitty__loadMoreBtn"
                              disabled={btn}
                              onClick={() =>
                                POPUPACTION("dummy", "Bid", Tokens[TabName]?.myBid)
                              }
                            >
                              Edit Bid
                            </Button>
                          ) : (
                            <Button
                              className="tf-button kitty__loadMoreBtn"
                              disabled={btn}
                              onClick={() => POPUPACTION("dummy", "Bid", {})}
                            >
                              Bid Now{" "}
                            </Button>
                          ))}

                        {/*                                                                                                         
                                                      <a href="#" className="tf-button" data-toggle="modal" data-target="#popup_bid">Place your bid</a>
                                                      
                                                      <a href="#" className="tf-button">Buy Now</a> */}
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </div>
          
          </section>


          {isEmpty(InfoDetail) && (
            <section className="tf-explore-more">
              <div className="tf-container">
                <div className="row">
                  {/* <div className="col-md-12">
                    <div className="tf-heading">
                      <h4 className="heading">Explore More</h4>
                    </div>
                  </div> */}
                  {Explores.map((item, index) => (
                    <div
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                      data-aos="zoom-in-up"
                    >
                      <Card
                        product={item}
                        index={index}
                        LikeList={LikeList}
                        LikedTokenList={LikedTokenList}
                        setLikedTokenList={setLikedTokenList}
                        LikeForwardRef={LikeForwardRef}
                      />
                    </div>
                  ))}
                  <div className="col-md-12">
                    <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                      <NavLink to="/explore" className="tf-button kitty__loadMoreBtn  style-8 loadmore">Explore More
                        {/* <i className="far fa-long-arrow-right"></i> */}
                      </NavLink>
                    </div>
                  </div>
                </div>

              </div>
            </section>
          )}
        </>
      }
      <Footer />
    </>
  )
}