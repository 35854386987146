import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {CreateOrder} from "../../actions/axioss/nft.axios";
import useContractProviderHook from "./../../actions/contractProviderHook";


export function Cancel({OpenPopup,closePop,owner,types,file,type,thumb,item , onhide}) {
  
    const push = useNavigate()
    const [Btn , SetBtn ] = useState('start')
    const[show9,setShow9]=useState(true);
    const handleClose9 = () => setShow9(false);
    const ContractCall = useContractProviderHook()
    const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
    const { payload } = useSelector(state => state.LoginReducer.User)
    const [once,setOnce]=useState(true) 
    const FormSubmit    =   async()  =>  {
      // debugger
        SetBtn('process')
       const id = toast.loading('Cancel Your order')
        var error   = await  ContractCall.Contract_Base_Validation()
        if(error) {  
            toast.update(id, { render:error, type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
            SetBtn('error')
     }
        else{
            if(types == "Cancel"){
                let cont = await ContractCall.cancel_order_721_1155(owner.NFTId)
                if(cont)
                {
                  console.log("cancelorgerifstattement")
                await Back_end(id,cont.HashValue)
                console.log("cassssncelorgerifstattement")

                handleClose9()
                }
                else { 
                    toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
                    SetBtn('try')}
                }
        else{
            await Back_end(id,'')
        }
        
    }
}
console.log("cancelorderitems", item)
    const Back_end = async(id,HashValue)=>{
      
      owner.NFTCreator = item?.NFTCreator
        owner.HashValue = HashValue
        owner.NFTPrice   =   0
         owner.CoinName     =   ''
         owner.NFTId = owner.NFTId
         owner.PutOnSale    =   'true'
         owner.PutOnSaleType    =   'UnlimitedAuction'
         owner.activity = types == "Cancel"?"CancelOrder":"CancelAuction";
         owner.NFTOwner   =   accountAddress
         owner.Category	=	item.Category
         owner.EmailId	=	payload.EmailId
                      owner.ContractType   =   item.ContractType
         owner.ContractAddress   =   item.ContractAddress
         owner.CollectionNetwork   =   item.CollectionNetwork 
        
console.log("dataincancelorger" , owner)
// debugger
         let Resp = await CreateOrder(owner)
         if (Resp.success == 'success'){ 
             toast.update(id, { render: "Cancelled Your Order Successfully", type: "success", isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
             SetBtn('done')
       
            closePop();
            window.location.reload();
          }
          else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
            SetBtn('try')
          }
    }

    useEffect(() => {
      BalanceCheck();
    }, [item, owner]);
  
    async function BalanceCheck() {
      SetBtn("process");
      if(once){
        let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
        console.log("ownneerrsnftbusdasdynowbalittemmm", Nftbalance , owner?.NFTBalance , Nftbalance , owner?.NFTOwner , item.ContractType  , owner?.NFTOwner);
        
        if ((Number(Nftbalance) != Number(owner?.NFTBalance) &&  item.ContractType == '1155' ) || ( Nftbalance.toLowerCase() !=  owner?.NFTOwner.toLowerCase()  &&  item.ContractType == '721') ) {
         
    
          toast.warning("You won't buy at this moment please refresh you data");
          setTimeout(() => {
            push("/");
          }, 1000);
        }
       
      }
      SetBtn("start");
      return ()=>{ setOnce(false)}
      
    }

   return  <Modal
   size="lg"
   aria-labelledby="contained-modal-title-vcenter"
   centered
   show={show9}
 >
   <Modal.Header>
   <button type="button" class="btn-close" aria-label="Close" onClick={()=>{
    closePop();
    onhide()}}></button>
     <Modal.Title id="contained-modal-title-vcenter">
     Cancel For Sale
     </Modal.Title>
   </Modal.Header>
   <Modal.Body>
  <div className='approvetransaction'>
     <p className='mt-4 receives'>Cancel For Sale</p>
     <p className='nstlx receives'><b>{item.NFTPrice} {item.CoinName}</b></p>
     <div className='connecwalletbtns kitty__cancelDualBtns kitty__putonsaleBtnHolder d-flex justify-content-between align-items-end mt-5'>
         {/* <button className="connectwallet primary">Cancel Order</button> */}
         <button 
         disabled    =   {Btn === "process"  ||  Btn ==="done" ? true : false} 
         className=" kitty__secondaryBtn kitty__putonsaleBtn" onClick={closePop}>Cancel</button>
         <button
                                    type        =   "button"
                                    className   =   "tf-button kitty__putonsaleBtn kitty__loadMoreBtn kitty__loadMorePad"
                                    disabled    =   {Btn == 'error' || Btn === "process"  ||  Btn ==="done" ? true : false} 
                                    onClick     =   {Btn == 'start' || Btn === "try" ? FormSubmit : null}
                                >   
                                {Btn == 'start' && 'Start' 
                                ||Btn == 'try' && 'Try-Again'
                                ||Btn == 'error' && 'Error' 
                                ||Btn == 'done' && 'Done' 
                                ||Btn == 'process' && 'In-Progress' 
                                 }
                                </button>
         </div> 
  </div>
   </Modal.Body>
 </Modal>
}