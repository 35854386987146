import React from 'react'

const KittyTeamCard = () => {

    const teamCard = [
        {
            img : require('../assets/images/kitty/member1.svg').default,
            name : 'Devon Lane',
            label : 'lorem ipsum'
        },
        {
            img : require('../assets/images/kitty/member2.svg').default,
            name : 'Floyd Miles',
            label : 'lorem ipsum'
        },
        {
            img : require('../assets/images/kitty/member3.svg').default,
            name : 'Robert Fox',
            label : 'lorem ipsum'
        },
        {
            img : require('../assets/images/kitty/member4.svg').default,
            name : 'Jenny Wilson',
            label : 'lorem ipsum'
        },
        {
            img : require('../assets/images/kitty/member5.svg').default,
            name : 'James Kitty',
            label : 'lorem ipsum'
        },
    ]
  return (
    <>
    {
        teamCard.map((item) => 
      <div className='kitty__teamCard'>
        <div className='kitty__teamCardTop d-flex justify-content-center align-items-center'>
            <img src={item.img} className='img-fluid kitty__teamCardMem' />
        </div>
        <div className='kitty__teamCardBot d-flex flex-column align-items-center p-4'>
            <h5 className='m-0 kitty__teamCardMemName text-center'>{item.name}</h5>
            <p className='m-0 kitty__teamCardMemPos text-center'>{item.label}</p>
            <button className='kitty__gradBtn rounded-5 w-100'>Follow</button>
        </div>
      </div>)
    }
    </>
  )
}

export default KittyTeamCard
